const API_TO_URL = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  signUpUpdate: '/sign-up-update',
  signOut: '/sign-out',
  resetPassword: '/reset-password',
  getProfile: '/get-profile',
  getProfileExtended: '/get-profile-extended',
  confirmAccount: '/confirm-account',
  updateSettings: '/update-settings',
  updateProfile: '/edit-profile',
  verifyProfile: '/verify-profile',
  getTasksAdmin: '/admin-get-tasks',
  getUsersAdmin: '/admin-get-users',
  getAdmins: '/admin-get-users',
  getDealsAdmin: '/admin-get-deals',
  deleteInvestor: '/delete-investor',
  setKYC: '/admin-set-kyc',
  getDealsInvestor: '/get-deals',
  getDealsBusiness: '/get-deals',
  getDealFlow: '/get-deals',
  getDeal: '/get-deal',
  getUser: '/get-user',
  getDealUser: '/get-deal-user',
  addDeal: '/add-deal',
  updateWithS3: '/update-with-s3',
  getDocuments: '/get-documents',
  getDocumentsAdmin: '/admin-get-documents',
  getNotifications: '/get-notifications',
  addToWatch: '/add-to-watch',
  signDocument: '/sign-doc',
  deleteCompanyFromPortfolio: '/delete-company-from-portfolio',
  addAdmin: '/add-admin',
  notify: '/notify',
  invoker: '/invoker',
  registerInvestment: '/register-investment',
  registerInterest: '/register-interest',
  deleteAccount: '/delete-account',
  setDealStatus: '/set-deal-status',
  editComment: '/edit-decline-comment',
  adminDelAccount: '/admin-delete-account',
  adminUpdateDeal: '/admin-update-deal',
  complianceUpdateDeal: '/compliance-update-deal',
  addToPortfolio: '/add-to-portfolio',
  addInvestor: '/add-investor',
  adminModifyInvestment: '/modify-investment',
  getWatchList: '/get-watch-list',
  deactivateNotifications: '/deactivate-notifications',
  adminDeleteDeal: '/delete-deal',
  notifyUser: '/notify-user',
  delDealDoc: '/delete-doc-from-deal',
  editDate: '/edit-date',
  editFee: '/edit-fee',
  editReturn: '/edit-return',
  delProfileDoc: '/delete-id-from-profile',
  setTutorial: '/set-tutor',
  rmFromWatch: '/rm-watch',
  typeForm: '/type-forms',
  getStats: '/stats',
  set: '/manage-pass',
  manualConfirm: '/manual-confirm',
  reporting: '/front-errors',
  anonReporting: '/anon-errors',
  getInvestment: '/get-investment',
  deleteRetained: '/delete-retained',
  gsi: '/gsi',
  getSpv: '/get-spv',
  createSpv: '/create-spv',
  getAdminSpv: '/get-spv-admin',
  setSpvStatus: '/set-spv-status',
  getAdminSpvInvestors: '/get-spv-investors',
  updateSpvInvestors: '/update-spv-investors',
  setSpvDocStatus: '/update-spv-doc-status',
  updateSpvExtended: '/update-spv-extended',
  notifyInvestors: '/notify-investors'
}

export default API_TO_URL
